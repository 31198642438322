import { Avatar, Popover } from 'antd';
import React from 'react';

const popoverContent = (
  <div>
    <a
      href="https://umd.nike.com/docs/docs/bringyourownbucket.html"
      target="_blank"
      rel="noopener noreferrer"
    >
      How to bring your own S3 bucket
    </a>
  </div>
);

const AmazonTargetHelp = () => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center'
    }}
  >
    <h1>Custom Amazon S3 Bucket</h1>
    <Popover content={popoverContent} >
      <Avatar className="target-avatar" size="small">
        ?
      </Avatar>
    </Popover>
  </div>
);

export default AmazonTargetHelp;