import React from 'react';
import {
  Select,
  FormSection,
  TextInput,
  Checkbox,
  TextArea,
  AmazonTargetHelp
} from '../../../components/index';
import { Popover, Avatar } from 'antd';
import './target.css';
import sanitizeNamePart from '../../../lib/util/sanitizeNamePart';
import {
  amazonRegionsOptions,
  amazonAclOptions,
  replicationTargetNames,
  disableCreateReplicationTargets
} from '../../../constants/defaultValues';

const onTableGroupChange = (selection, setSelectedTableGroup) => {
  setSelectedTableGroup(selection.value);
};

const onTableNameChange = (name, setNewTableName) => {
  const sanitizedName = sanitizeNamePart(name);
  setNewTableName(sanitizedName);
};

const onDescriptionChange = (event, setTableDescription) => {
  setTableDescription(event.target.value);
};

const popoverTargetDefinitionHelpContent = (
  <div>
    <div>
      <p style={{ fontWeight: 'bold', color: 'var(--er-accent)' }}>
        Table Group pattern rules:
      </p>
      <ul>
        <li>Has to start with a segment containing one or more letters.</li>
        <li>Can be followed by segments containing letters or digits.</li>
        <li>
          Subsequent segments are separated by either a space or an underscore.
        </li>
        <li>It cannot start with the prefix &apos;SYS_&apos;</li>
        <li>Tha maximum length allowed is 28 characters</li>
      </ul>
      <p style={{ fontWeight: 'bold', color: 'var(--er-accent)' }}>
        Table pattern rules:
      </p>
      <ul>
        <li>Has to start with a segment containing one or more letters.</li>
        <li>Can be followed by segments containing letters or digits.</li>
        <li>
          Subsequent segments are separated by either a space or an underscore.
        </li>
        <li>Tha maximum length allowed is 28 characters</li>
      </ul>
    </div>
  </div>
);

const TableGroupHelp = () => (
  <div
    data-testid="target-definition"
    style={{
      display: 'flex',
      alignItems: 'center'
    }}
  >
    <h1>1. Target Definition</h1>
    <Popover
      content={popoverTargetDefinitionHelpContent}
      title="Target Definition"
    >
      <Avatar className="target-avatar" size="small">
        ?
      </Avatar>
    </Popover>
  </div>
);

const Target = props => {
  const {
    core,
    tableGroups,
    selectedTableGroup,
    setSelectedTableGroup,
    setNewTableName,
    setTableDescription,
    formValues,
    setS3BucketField,
    toggleFormCheckboxes
  } = props;

  const { newTableName, tableDescription } = core;

  const handleCheckboxChange = targetName => {
    toggleFormCheckboxes(targetName);
  };

  const handleInputChange = field => {
    setS3BucketField(field);
  };

  return (
    <div>
      <div data-testid="target-definition">
        <FormSection className="formGeneralSection" title={<TableGroupHelp />}>
          <div style={{ width: '400px' }}>
            <div className="target-medium-size-component">
              <Select
                label="Table Group"
                options={tableGroups.map(tableGroup => {
                  return { value: tableGroup.id, label: tableGroup.name };
                })}
                value={
                  selectedTableGroup
                    ? {
                        value: selectedTableGroup.id,
                        label: selectedTableGroup.name
                      }
                    : null
                }
                placeholder="Select a Table Group"
                onChange={selection =>
                  onTableGroupChange(selection, setSelectedTableGroup)
                }
              />
            </div>
            <div className="target-medium-size-component">
              <TextInput
                data-testid="table-name-input"
                value={newTableName}
                onChange={event =>
                  onTableNameChange(event.target.value, setNewTableName)
                }
                required
                label="Table Name"
                border={false}
                placeholder="Enter a Table Name"
                hasErrors={false}
                maxLength={63}
                full
              />
            </div>{' '}
            <div className="target-medium-size-component">
              <TextArea
                value={tableDescription}
                required
                data-testid="table-description-text-area"
                onChange={event =>
                  onDescriptionChange(event, setTableDescription)
                }
                label="Description"
                border={false}
                full
              />
            </div>
          </div>
        </FormSection>
      </div>

      { !disableCreateReplicationTargets && (
      <div data-testid="replication-targets">
        <FormSection
          className="formGeneralSection"
          title="2. Replication Targets"
          subtitle="In order to set up GEM data replication, please enable the replication targets. They are not manageable after setup."
        >
          <Checkbox
            key={replicationTargetNames.snowflake}
            checked={formValues.snowflake.checked}
            onChange={event =>
              handleCheckboxChange(event.target.name.toLowerCase())
            }
            name={replicationTargetNames.snowflake}
            label={replicationTargetNames.snowflake}
          />

          <Checkbox
            key={replicationTargetNames.s3}
            checked={formValues.s3.checked}
            onChange={event =>
              handleCheckboxChange(
                event.target.name.split(' ').pop().toLowerCase()
              )
            }
            name={replicationTargetNames.s3}
            label={replicationTargetNames.s3}
          />
        </FormSection>
      </div>
      )}
      {formValues.s3.checked && !disableCreateReplicationTargets && (
        <div data-testid="amazon-s3-bucket">
          <FormSection
            className="formGeneralSection"
            title={<AmazonTargetHelp />}
          >
            <div
              data-testid="bucket-region"
              className="target-medium-size-component"
            >
              <Select
                label="Region"
                options={amazonRegionsOptions}
                value={formValues.s3.s3BucketFields.region}
                placeholder="Select a Region"
                onChange={option =>
                  handleInputChange({
                    region: option
                  })
                }
              />
            </div>
            <div
              data-testid="bucket-acl"
              className="target-medium-size-component"
            >
              <Select
                label="ACL"
                options={amazonAclOptions}
                value={formValues.s3.s3BucketFields.acl}
                placeholder="Select an ACL"
                onChange={option =>
                  handleInputChange({
                    acl: option
                  })
                }
              />
            </div>
            <div
              data-testid="bucket-url"
              className="target-medium-size-component"
            >
              <TextInput
                value={formValues.s3.s3BucketFields.uri}
                onChange={event => {
                  handleInputChange({
                    uri: event.target.value
                  });
                }}
                required
                label="URI"
                border={false}
                placeholder="Enter the Custom Amazon S3 Bucket URI"
                hasErrors={false}
                maxLength={128}
                full
              />
            </div>
          </FormSection>
        </div>
      )}
    </div>
  );
};

export default Target;
